import Echo from 'laravel-echo';
import IMask from 'imask';

window.Pusher = require('pusher-js');
window.moment = require('moment');
window.IMask = IMask;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    // cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_HOST.replace(/((http:\/\/)|(https:\/\/))/g, ''),
    encrypted: process.env.MIX_PUSHER_HOST.includes('https://'),
    wsPort: process.env.MIX_PUSHER_PORT,
    wssPort: process.env.MIX_PUSHER_PORT,
    forceTLS: process.env.MIX_PUSHER_HOST.includes('https://'),
    enabledTransports: ['wss', 'ws']//process.env.MIX_PUSHER_HOST.includes('https://') ? ['wss'] : ['ws']
});
window.Echo.disconnect()
